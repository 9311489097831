import React from 'react'
import PropTypes from 'prop-types'
import ButtonPopUpStyled from './styledComponent'

import profileIcon from '../../asset/images/sidebar/profile-sidebar.svg'
import SettingIcon from '../../asset/images/sidebar/setting-sidebar.svg'

import MESSAGE from './message'

class ButtonPopUp extends React.PureComponent {
  state = {
    popupOpen: false
  }

  componentDidMount() {
    document.addEventListener('mousedown', (e) => this.handleClickOutSide(e))
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', (e) => this.handleClickOutSide(e))
  }

  togglePopUp = () => {
    this.setState((prevState) => ({ popupOpen: !prevState.popupOpen }))
  }

  closePopUp = () => {
    this.setState({
      popupOpen: false
    })
  }

  handleClickOutSide(e) {
    if (this.popup && !this.popup.contains(e.target)) {
      this.closePopUp()
    }
  }

  onChange(value) {
    this.props.onPopUpOptionChange(value)
    this.closePopUp()
  }

  getUserProfileRow() {
    return (
      <div className="profile-row-container section">
        <div className="img-icon">
          <img src={profileIcon} alt="" />
        </div>
        <div className="detail prevent-text-overflow">{this.props.userData.email}</div>
      </div>
    )
  }

  getNavigatorRow() {
    let output = null
    if (this.props.navigatorData.length > 0) {
      const listNav = this.props.navigatorData.map((menuData) => {
        return (
          <div key={menuData.value} className="menu" onClick={() => this.onChange(menuData.value)}>
            {menuData.text}
          </div>
        )
      })
      output = <div className="naivgator-row-container section">{listNav}</div>
    }
    return output
  }

  getMessage(property) {
    const msg = MESSAGE[this.props.language]
    return msg[property]
  }

  getLanguageRow() {
    const languageMenu = this.props.languageData.map((menu) => {
      const className = menu.value === this.props.language ? 'menu selected' : 'menu'
      return (
        <div key={menu.value} className={className} onClick={() => this.onChange(menu.value)}>
          {menu.text}
        </div>
      )
    })
    return (
      <div className="language-row-container section">
        <div className="title">{this.getMessage('language')}</div>
        <div className="language-menu-container">{languageMenu}</div>
      </div>
    )
  }

  getBottomRow() {
    const menus = this.props.bottomMenuData.map((menu) => {
      return (
        <div key={menu.value} className="menu" onClick={() => this.onChange(menu.value)}>
          {menu.text}
        </div>
      )
    })
    return <div className="signout-row-container section">{menus}</div>
  }

  renderButtonPopUpOption() {
    let popupClassName = 'popup-wrapper text-style'
    if (this.state.popupOpen) {
      popupClassName += ' open'
    }
    const profileRow = this.getUserProfileRow()
    const navigatorRow = this.getNavigatorRow()
    const languageRow = this.getLanguageRow()
    const bottomRow = this.getBottomRow()
    return (
      <div className={popupClassName} ref={(pu) => (this.popup = pu)}>
        {profileRow}
        {navigatorRow}
        {languageRow}
        {bottomRow}
      </div>
    )
  }

  render() {
    const btnClassName = this.props.className ? `button-popup-container ${this.props.className}` : 'button-popup-container'
    return (
      <ButtonPopUpStyled id={this.props.id}>
        <div className={btnClassName} onClick={this.togglePopUp}>
          <img src={SettingIcon} alt="" width={28} height={28} />
        </div>
        {this.renderButtonPopUpOption()}
      </ButtonPopUpStyled>
    )
  }
}

ButtonPopUp.defaultProps = {
  userData: {
    email: ''
  },
  language: 'EN'
}

ButtonPopUp.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  userData: PropTypes.shape({
    email: PropTypes.string
  }),
  languageData: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired
    })
  ).isRequired,
  navigatorData: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired
    })
  ).isRequired,
  onPopUpOptionChange: PropTypes.func.isRequired,
  language: PropTypes.string
}

export default ButtonPopUp
