import styled from 'styled-components'

export default styled.div`
  position: relative;
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  .button-popup-container {
    display: flex;
    &:hover {
      cursor: pointer;
    }
  }
  .popup-wrapper {
    width: 160px;
    position: absolute;
    left: 75px;
    bottom: 20px;
    border-radius: 5px;
    background: white;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
    font-family: 'Prompt', sans-serif;
    font-size: 12px;
    color: ${(props) => props.theme.darkBackground};
    z-index: 1;
    display: none;
    &.open {
      display: block;
    }
    .section {
      position: relative;
      padding: 10px;
      &::after {
        content: '';
        width: calc(100% - 20px);
        height: 1px;
        background: ${(props) => props.theme.greyDisabled};
        position: absolute;
        bottom: 0px;
        left: 10px;
      }
      &:last-child {
        &:after {
          display: none;
        }
      }
      &:hover {
        background: ${(props) => props.theme.cardTitle};
      }
    }
    .profile-row-container {
      display: flex;
      align-items: center;
      &:hover {
        cursor: pointer;
        text-decoration: underline;
      }
      .img-icon {
        width: 22px;
        height: 22px;
        background: #ddd;
        border-radius: 50%;
        margin-right: 10px;
        img {
          padding: 5px;
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
      .detail {
        width: 98px;
      }
    }
    .naivgator-row-container {
      .menu {
        margin-bottom: 4px;
        cursor: pointer;
        &:last-child {
          margin: 0px;
        }
        &:hover {
          text-decoration: underline;
        }
      }
    }
    .language-row-container {
      .title {
        margin-bottom: 5px;
      }
      .language-menu-container {
        display: flex;
        .menu {
          width: 100%;
          font-size: 12px;
          text-align: center;
          &.selected {
            color: ${(props) => props.theme.jadeGreen};
          }
          &:hover {
            cursor: pointer;
            text-decoration: underline;
          }
        }
      }
    }
    .signout-row-container {
      .menu {
        &:hover {
          cursor: pointer;
          text-decoration: underline;
        }
      }
    }
  }
`
