const MESSAGE = {
  EN: {
    language: 'Language'
  },
  TH: {
    language: 'ภาษา'
  }
}

export default MESSAGE
